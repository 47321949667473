@import "../../styles/constants";
@import "../../styles/breakpoints";


.section {
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;

  &.collapsed {
    padding-bottom: 12px;
  }

  .section_title {
    font-weight: 500;
    font-size: 20px;
    margin: 0px;
    margin-bottom: 22px;
    display: flex;

    .triangle {
      margin-left: auto;
      background: rgb(53,53,53);
      width: 1.2em;
      height: 1.2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      i {
        color: #828F99;
        position: relative;
        top: 7%;

        &.fa-caret-left {
          left: -2px;
        }
      }
    }
  }
}