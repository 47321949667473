@import "../../styles/breakpoints";

.frame-container {
  position: relative;
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(./white-noise.gif);

  img, video {
    width: 100%;
    display: block;
  }

  .double-bounce1, .double-bounce2 {
    background: white;
  }

  .zoom-btn {
    display: flex;
    width: 30px;
    height: 30px;
    background: rgba(0,0,0,0.7);
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 2px;
    font-size: 16px;
    z-index: 100;

    i {
      color: white;
    }
  }
}

@include mobile {
  .frame-container .zoom-btn {
    display: none;
  }
}