@import "../../styles/constants";
@import "../../styles/breakpoints";

$dropdown-bk: #353E47;

.dropdown-btn {
  background: $dropdown-bk;
  border-radius: 2px;
  font-family: Roboto Mono;
  height: 30px;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 8px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .fa-chevron-down {
    margin-left: 14px;
    position: relative;
    top: 1px;
  }
}

.dropdown-menu {
  position: absolute;
  background: $dropdown-bk;
  white-space: nowrap;
  list-style: none;
  border-radius: 2px;
  z-index: 150;
  overflow: auto;
  font-family: Roboto Mono;
  padding: 4px 0px;

  a{
    line-height: 36px;
    padding: 0px 12px;
    display: block;
    color: white;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    border-left: 4px solid transparent;
    font-weight: 300;

    &:hover {
      background: rgba(255,255,255,0.2);
    }

    &.active {
      border-color: white;
      font-weight: 500;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .loading-bar {
    margin: 0 -20px;
  }
}

@include mobile {
  .turnaround-info {
    .in-flight-no, .out-flight-no {
      font-size: 24px;
    }

    .sobt-value {
      font-size: 24px;
    }
  }

  .dropdown-btn {
    font-size: 12px;
  }

  .dropdown-menu {
    a {
      font-size: 12px;
    }
  }
}
