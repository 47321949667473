@import "../../styles/constants";
@import "../../styles/breakpoints";

.turnaround-info {
  padding-top: 23px;
  padding-bottom: 16px;
  padding-right: 36px;
  display: grid;
  display: -ms-grid;
  text-transform: uppercase;

  grid-template-rows: 30px auto 1fr;
  grid-template-columns: auto 1fr auto auto;
  grid-gap: 10px 0px;
  -ms-grid-rows: 30px 10px auto 10px 1fr;
  -ms-grid-columns: auto 1fr auto auto;

  .aircraft-gate-cell {
    grid-column: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  .aircraft-type {
    font-size: 14px;
    color: #828F99;
    text-transform: uppercase;
  }

  .overdue-status {
    grid-column: 4;
    grid-row: 1;
    -ms-grid-column: 4;
    -ms-grid-row: 1;
    text-align: right;

    span {
      color: #E2E2E2;
      background: #353E47;
      border-radius: 2px;
      line-height: 20px;
      padding: 0px 2px;
      font-family: Roboto Mono;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
    }

    i {
      color: rgba(53, 62, 71, 0.57);;
    }
  }

  .in-flight-no {
    grid-column: 1;
    grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    color: #828F99;

    .fa-circle {
      font-size: 8px;
      vertical-align: middle;
      margin: 0px 12px;
    }
  }

  .in-flight-no, .out-flight-no {
    font-family: Roboto Mono;
    font-weight: 300;
    font-size: 32px;
    margin-left: -2px;
  }

  .out-flight-no {
    grid-column: 2;
    grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    color: #E2E2E2;
  }

  .airport-to, .airport-from {
    font-weight: 500;
    font-size: 14px;
    color: #828F99;

    i {
      font-size: 70%;
      vertical-align: middle;
    }
  }

  .airport-from {
    grid-column: 1;
    grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-row: 5;

    i {
      margin-left: 4px;
    }
  }

  .airport-to {
    grid-column: 2;
    grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-row: 5;

    i {
      margin-right: 4px;
    }
  }

  .sobt-label {
    grid-column: 3;
    grid-row: 2;
    -ms-grid-column: 3;
    -ms-grid-row: 3;
    align-self: end;
    -ms-grid-row-align: end;
    top: -3px;
  }

  .sobt-label, .pobt-label {
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 12px;
    color: #828F99;
    text-transform: uppercase;
    position: relative;
  }

  .sobt-value {
    grid-column: 4;
    grid-row: 2;
    -ms-grid-column: 4;
    -ms-grid-row: 3;

    font-family: Roboto Mono;
    font-weight: 300;
    font-size: 32px;
    text-align: right;
    color: #E2E2E2;
    margin-left: 16px;
  }

  .pobt-label {
    grid-column: 3;
    grid-row: 3;
    -ms-grid-column: 3;
    -ms-grid-row: 5;
  }

  .pobt-value {
    grid-column: 4;
    grid-row: 3;
    -ms-grid-column: 4;
    -ms-grid-row: 5;

    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: #828F99;
  }
}

@mixin dots {
  content: '';
  flex: 1;
  border-bottom: 1px dotted #4F5C66;
}

@include mobile {
  .turnaround-info {
    grid-template-rows: 20px auto auto auto auto;
    grid-template-columns: auto 1fr;
    grid-gap: 10px 0px;
    -ms-grid-rows: 30px 10px auto 10px 1fr;
    -ms-grid-columns: auto 1fr auto auto;

    .sobt-label {
      grid-column: 1;
      grid-row: 4;
      -ms-grid-column: 1;
      -ms-grid-row: 4;
      display: flex;
      position: static;

      &::after {
        @include dots;
      }
    }

    .sobt-value {
      grid-column: 2;
      grid-row: 4;
      -ms-grid-column: 2;
      -ms-grid-row: 4;
      margin-left: 1px;
      display: flex;

      &::before {
        @include dots;
      }
    }

    .pobt-label {
      grid-column: 1;
      grid-row: 5;
      -ms-grid-column: 1;
      -ms-grid-row: 5;
      display: flex;

      &::after {
        @include dots;
      }
    }

    .pobt-value {
      grid-column: 2;
      grid-row: 5;
      -ms-grid-column: 2;
      -ms-grid-row: 5;
      margin-left: 1px;
      display: flex;

      &::before {
        @include dots;
      }
    }

    .fa-circle {
      font-size: 5px !important;
      margin: 0px 16px !important;
    }
  }
}
