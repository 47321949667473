@import "../../styles/constants";
@import "../../styles/breakpoints";

.stand-toolbar {
  background: $bk-dark;
  grid-row: 2;
  grid-column: 2;
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  display: flex;
  align-items: center;
  padding-left: 27px;
  padding-right: 25px;

  .overview {
    font-weight: 500;
    font-size: 16px;
    color: #828F99;
    text-decoration: none;
  }

  & > * {
    margin-right: 14px;
  }

  .stand-dropdown {
    min-width: 140px;
  }

  .export {
    margin-left: auto;
    margin-right: 0px;
    font-size: 14px;
    color: #A9B1B8;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.06em;
  }
}

@include mobile {
  .stand-toolbar {
    display: block;
    padding: 0px;
    margin-bottom: 8px;

    .overview, .export {
      display: none;
    }

    $dropdown-btn-height: 52px;

    .dropdown-btn {
      height: $dropdown-btn-height;
      border-radius: 0px;
      .title {
        line-height: 20px;
      }
    }

    .stand-dropdown {
      display: flex;
      margin-right: 0px;
      margin-bottom: 1px;
    }

    .turnaround-selector {
      display: flex;
      margin-right: 0px;

      .dropdown-btn {
        flex: 1;
        width: auto;
        margin-right: 1px;
      }

      .prev, .next {
        border-radius: 0px;
        width: $dropdown-btn-height;
      }

      .prev {
        margin-right: 1px;
      }

    }
  }

  .stand-dropdown.dropdown-menu, .turnaround-dropdown.dropdown-menu {
    width: 100% !important;
    right: 0px !important;
  }
}