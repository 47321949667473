@import "../../styles/constants";
@import "../../styles/breakpoints";

header {
  background: $bk-light;
  grid-row: 1;
  grid-column: 2;
  padding-left: 26px;
  padding-right: 26px;
  display: flex;
  align-items: center;
  -ms-grid-row: 1;
  -ms-grid-column: 2;

  .logo {
    height: 25px;
    position: relative;
    //top: -4px;
  }

  .user-dropdown.dropdown-btn {
    margin-left: auto;
    background: none;
    white-space: nowrap;

    &>i {
      display: none;
    }

    span {
      font-size: 16px;
      font-family: Work Sans;
      color: #A9B1B8;
    }

    .fa-angle-down {
      margin-left: 6px;
      position: relative;
      top:2px;
    }

    img {
      height: 32px;
      margin-left: 12px;
      vertical-align: middle;
    }
  }
}

.user-dropdown.dropdown-menu {
  background: white;
  transform: translateY(14px);
  font-family: Work Sans;

  a {
    padding: 12px 16px;
    color: black;
    line-height: initial;

    &.active {
      border-left-color: black;
      background: rgba(169, 177, 184, 0.2);
    }

    &:hover {
      background: rgba(0,0,0,0.2);
    }

    &.timeformat-option {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .dropdown_separator {
    height: 1px;
    opacity: 0.4;
    background: #757779;
  }

  .dropdown_section-title{
    font-weight: 500;
    font-size: 12px;
    color: #4D4F51;
    padding: 10px 16px;
    border-left: 4px solid transparent;
  }
}

@include mobile {
  header {
    padding: 0px 8px;

    .logo {
      height: 20px;
    }

    .user-dropdown.dropdown-btn {
      span {
        font-size: 10px;
      }
    }
  }

}