@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../../styles/breakpoints";

.turnaround-timing {
  display: flex;
  margin: 0px -10px;

  .card {
    flex: 1;
    margin: 0px 10px;
    padding: 18px 0px;
    background: #1A1E23;
    border-radius: 2px;

    h4 {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.06em;
      margin: 0px;
      margin-bottom: 26px;
      padding: 0px 20px;
    }

    .card-row {
      font-size: 12px;
      display: flex;
      color: #828F99;
      margin-bottom: 12px;
      align-items: flex-end;
      padding: 0px 20px;

      label {
        font-weight: 500;
        order:1;
        text-transform: uppercase;
      }

      &::after {
        order:2;
        flex: 1;
        content: '';
        border-bottom: 1px dotted #828F99;
      }

      span {
        order:3;
        font-family: Roboto Mono;

        &.big {
          font-size: 32px;
          color: #E2E2E2;
          font-weight: 300;
        }
      }

      &.delay {
        margin-top: -6px;
        &::after {
          opacity: 0;
        }
      }

      &.tobt-row {
        background: #565D63;
        padding-top: 12px;
        padding-bottom: 12px;
        color: #E2E2E2;

        &::after {
          opacity: 0;
        }
      }
    }

    .pobt-row-wrapper {
      background: #2C333A;
      padding-top: 16px;
      padding-bottom: 16px;

      color: #E2E2E2;

      .card-row:first-child {
        align-items: center;
      }

      .card-row {
        margin-bottom: 2px;
        margin-top: 0px;

        &::after {
          opacity: 0;
        }
      }
    }
  }
}

@include mobile {
  .turnaround-timing {
    margin: 0px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    display: -ms-grid;
    -ms-grid-rows: auto auto;
    -ms-grid-columns: auto auto;

    .card {
      margin: 10px;
    }
  }
}