@import "../../styles/constants";

$sidebar-width: 370px;
$sidebar-width-collapsed: 60px;

.sidebar_header {
  grid-row: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  background: $bk-light;
  display: flex;
  align-items: center;
  padding-left: 22px;
  padding-right: 8px;
  width: $sidebar-width;
  margin-right: $grid-gap;

  &.collapsed {
    width: $sidebar-width-collapsed;
    cursor: pointer;
  }

  .fa-bell {
    color: #828F99;
    margin-right: 8px;
    font-size: 16px;
    vertical-align: middle;
  }

  h2 {
    color: white;
    flex: 1;
    font-weight: 500;
    font-size: 20px;
  }

  a {
    background: rgba(226, 226, 226, 0.15);
    border-radius: 2px;
    display: inline-block;
    color: #828F99;
    padding: 1px 4px;
    font-size: 22px;

    .fa-angle-double-left {
      position: relative;
      top: 1px;
    }
  }
}

.sidebar_toolbar {
  grid-row: 2;
  grid-column: 1;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  background: $bk-dark;
  padding-left: 22px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  position: relative;
  width: $sidebar-width;
  margin-right: $grid-gap;

  &.collapsed {
    width: $sidebar-width-collapsed;
    cursor: pointer;
    padding: 0px;
    justify-content: center;

    .tab {
      margin: 0px;

      label {
        color: white !important;
        font-family: "Roboto Mono";
        margin: 0px;
      }
    }
  }

  .tab {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 15px;

    label {
      font-weight: normal;
      font-size: 16px;
      color: #A9B1B8;
      margin-right: 6px;
      cursor: pointer;
    }

    .badge {
      background: #bd4e2c;
      color: white;
      font-size: 12px;
      padding: 2px;
      border-radius: 1px;
    }

    &.active label {
      color: white;
    }

    &.active::after {
      content: '';
      height: 2px;
      width: 100%;
      background: white;
      position: absolute;
      display: block;
      bottom: 1px;
    }
  }

  .fa-search {
    color: #828F99;
    margin-left: auto;
    font-size: 16px;
  }
}

.sidebar_content {
  grid-row: 3;
  grid-column: 1;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  background: $bk-mid;
  overflow: auto;
  width: $sidebar-width;
  margin-right: $grid-gap;
  margin-bottom: 1px;
  position: relative;

  &.collapsed {
    width: $sidebar-width-collapsed;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 13px;

    .sidebar_badge {
      display: block;
      width: 32px;
      line-height: 32px;
      margin-bottom: 13px;

      font-family: Roboto Mono;
      font-weight: 500;
      font-size: 14px;
      color: #E2E2E2;
      text-align: center;
      background: rgba(187, 220, 251, 0.2);
      border-radius: 2px;
    }
  }

  .section {
    padding: 0px;

    .section_title {
      margin-bottom: 0px;
      padding-left: 20px;
      padding-right: 8px;
      align-items: center;
      height: 44px;
      font-size: 12px;
      text-transform: uppercase;
      border-bottom: 1px solid #0E0E10;

      .triangle {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .alert {
    border-left: 4px solid #3A4248;
    padding-left: 12px;
    padding-top: 17px;
    padding-bottom: 27px;
    padding-right: 10px;
    position: relative;

    h3 {
      display: flex;
      margin: 0px;
      align-items: center;
      margin-bottom: 12px;

      .flight-info {
        font-family: Roboto Mono;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #E2E2E2;
        background: rgba(187, 220, 251, 0.2);
        border-radius: 2px;
        padding: 0px 4px;
        margin-right: 8px;
        text-decoration: none;
        white-space: nowrap;

        .arrow {
          margin-left: 9px;
          transform: rotate(45deg);
        }
      }

      .time {
        font-weight: 500;
        font-size: 10px;
        text-transform: uppercase;
        color: #828F99;
      }

      .is-current-turn {
        color: white;
        margin-left: auto;
        font-weight: 600;
        font-size: 10px;
        text-transform: uppercase;
        white-space: nowrap;
        padding-left: 4px;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
          margin-right: 0.5rem;
          font-size: 40%;
          vertical-align: middle;
          position: relative;
          top: -1px;
        }
      }
    }

    p {
      margin: 0px;
      color: white;
      font-size: 14px;
      line-height: 1.4;
    }

    &:after {
      content: '';
      position: absolute;
      left: -4px;
      right: 0px;
      bottom: 0px;
      height: 1px;
      background: $bk-darkest;
    }
  }

  .loading-more-spinner {
    display: table;
    position: static;
    margin: auto;
  }
}

.sidebar-footer {
  grid-row: 4;
  grid-column: 1;
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  background: $bk-mid;
  padding-left: 22px;
  display: flex;
  align-items: center;
  width: $sidebar-width;
  margin-right: $grid-gap;

  &.collapsed {
    width: $sidebar-width-collapsed;
    padding: 0px;
    justify-content: center;

    svg {
      margin-right: 0px;
    }
  }

  svg {
    height: 20px;
    margin-right: 15px;
  }

  span {
    font-size: 10px;
    color: #8C8C8D;
  }
}