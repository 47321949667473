@import "../../styles/constants";
@import "../../styles/breakpoints";

.map {
  grid-column: 2;
  grid-row-start: 3;
  grid-row-end: 5;
  -ms-grid-column: 2;
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  background: $bk-mid;
  padding: 7px;
  overflow: hidden;

  svg {
    display: block;
    height: 100%;
    width: 100%;

    &.low-detailed {
      .st3, .st4, .gate text {
        display: none;
      }
    }

    .gate {
      cursor: pointer;
    }

    .gate-label {
      fill: white;
      font-family: Roboto Mono;
      font-weight: 500;
      font-size: 10px;
      text-transform: uppercase;
    }

    .cell-bk {
      fill: #272e37;
      opacity: 1;
    }

    .cell-header {
      fill: #848F98;
      opacity: 1;
    }
    .plane {
      fill: white;
    }
  }
}

.gate-popup {
  position: fixed;
  background: white;
  padding: 20px;

  $triangle-size: 8px;

  &::after {
    content: '';
    border: $triangle-size solid transparent;
    position: absolute;
  }

  &.bottom {
    transform: translate(-50%, $triangle-size + 2px);

    &::after {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom-color: white;
    }
  }

  &.top {
    transform: translate(-50%, -$triangle-size - 2px);

    &::after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top-color: white;
    }
  }

  .turnaround-info {
    padding: 0px;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: 30px auto auto;
    -ms-grid-columns: auto auto;

    * {
      color: #4D4F51;
    }

    .aircraft-gate-cell {
      grid-column-start: 1;
      grid-column-end: 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
    }

    .stand-id {
      background: #353E47;
      border-radius: 2px;
      line-height: 20px;
      padding: 0px 4px;
      margin-right: 12px;
      color: white;
      font-family: Roboto Mono;
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
    }

    .sobt-label, .pobt-label, .sobt-value, .pobt-value {
      display: none;
    }

    .in-flight-no, .out-flight-no {
      font-weight: 500;
      font-size: 20px;
      margin-left: 0px;
    }

    .airport-from, .airport-to {
      font-weight: 500;
      font-size: 12px;
    }
  }
}