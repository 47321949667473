.bbox {
  position: absolute;
  border: 1px solid red;
  pointer-events: none;
  z-index: 100;

  span {
    position: absolute;
    color: white;
    left: -1px;
    bottom:100%;
    padding: 3px;
    font-size: 80%;
  }

  span.inside {
    bottom: auto;
    top: 0px;
  }
}
