@import "../../styles/constants";
@import "../../styles/breakpoints";

.datetime-input {
  input {
    border: none;
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 14px;
    background: none;
  }

  .react-datepicker-wrapper {
    display: inline-block;

    input {
      width: 100px;
    }
  }

  .time {
    margin-left: 3px;
    width: 60px;
  }

  .react-datepicker__tab-loop {
    display: inline-block;
  }
}