@import "../../styles/constants";

.turnaround-alert {
  background: rgba(218, 151, 89, 0.2);
  padding: 17px;
  font-weight: 500;
  font-size: 14px;
  color: #E18813;

  a {
    text-decoration: underline;
  }
}