@import "../../styles/constants";

.support-button-container {
  position: fixed;
  right: 44px;
  bottom: 12px;
  z-index: 9999999;

  .support-button {
    display: flex;
    height: 44px;
    width: 44px;
    justify-content: center;
    align-items: center;
    right: 12px;
    bottom: 12px;
    z-index: 9999999;

    border-radius: 50%;
    background: #4F5C66;
    border: 1px solid #828F99;
    opacity: 1;

    & > img {
      width: 26px;
    }
  }

  .support-popup {
    position: absolute;
    right: 0px;
    bottom: calc(100% + 12px);
  }
}