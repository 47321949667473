@import "../../styles/constants";
@import "../../styles/breakpoints";

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  &>.warning {
    background: #efefef;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 30px;
    color: #2a3135;
    text-align: center;
    white-space: nowrap;
    padding: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.home {
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 60px auto 1fr 51px;
  display: -ms-grid;
  -ms-grid-columns: auto 1fr;
  -ms-grid-rows: 60px auto 1fr 51px;

  overflow: hidden;

  .error-message {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column: 2;
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
  }

  .footer-logo {
    position: fixed;
    left: 26px;
    bottom: 16px;
    opacity: 0.7;
    z-index: 100;

    svg {
      height: 20px;
      margin-right: 15px;
      vertical-align: middle;
    }

    span {
      font-size: 10px;
      color: #8C8C8D;
      vertical-align: middle;
      position: relative;
      top: 3px;
    }
  }

  &.win-os:only-child {
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px #ddd;
      border: solid 3px transparent;
    }

    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px #666;
      border: solid 3px transparent;
    }

    -ms-overflow-style: -ms-autohiding-scrollbar;

    scrollbar-color: $grey-medium $bk-grey;
    scrollbar-width: thin;
  }
}

@include mobile {
  .home {
    grid-template-rows: 40px auto 1fr 51px;
    -ms-grid-rows: 40px auto 1fr 51px;

    .footer-logo {
      display: none;
    }
  }
}