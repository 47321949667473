@import "../../styles/constants";

.frame-container {
  .frame-unavailable {
    height: 100%;
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;

    .exception {
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%,-50%);
      text-align: center;

      .msg {
        color: white;
        letter-spacing: 0.51px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        white-space: nowrap;
        font-size: 16px;

        i {
          font-size: 24px;
          display: table;
          margin: auto;
          margin-bottom: 12px;
        }

        .timestamp-info {
          position: static;
          display: inline-flex;
          margin: 0px 12px;

          .time {
            background: white;
            color: $bk-dark;
          }
        }
      }

      @media (max-width: 820px) {
        .msg {
          span {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            div {
              margin: 6px 0 !important;
            }
          }
        }
      }

      img {
        display: block;
        margin: auto;
        margin-top: 16px;
      }
    }
  }
}