@import "../../styles/constants";

.turnaround-stream {
  position: relative;

  .toolbar {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 100%);
    height: 32px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    z-index: 10;

    .cameras {
      margin-right: auto;

      a {
        font-weight: 500;
        font-size: 12px;
        color: #A4A4A4;
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 16px;
        }

        &.active {
          color: white;
          border-bottom-color: white;
        }
      }
    }

    .time {
      font-family: Roboto Mono;
      font-weight: 500;
      font-size: 12px;
      background: white;
      line-height: 20px;
      height: 20px;
      padding: 0px 4px;
      border-radius: 2px;
      color: black;
      margin-right: 5px;
    }

    .live-indicator {
      background: rgba(226, 226, 226, 0.4);
      border-radius: 2px;
      line-height: 20px;
      padding: 0px 4px;
      letter-spacing: 0.09em;

      .fa-circle{
        color: #C45D3C;
        font-size: 6px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }
}