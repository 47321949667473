@import "../../styles/constants";
@import "../../styles/breakpoints";

.turnaround {
  $padding-h: 27px;

  grid-column: 2;
  grid-row-start: 3;
  grid-row-end: 5;
  -ms-grid-column: 2;
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  background: $bk-mid;
  padding: 0px $padding-h;
  overflow: auto;

  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 450px auto;
  display: -ms-grid;
  -ms-grid-rows: auto auto 1fr;
  -ms-grid-columns: 450px auto;

  &.video-zoomed {
    .video-cell {
      width: auto;
    }
  }

  .turnaround-info, .empty-apron {
    grid-row: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;

    padding-bottom: 16px;
    border-bottom: 1px solid #161619;
  }

  .empty-apron {
    font-weight: 300;
    font-size: 32px;
    color: #828F99;
    padding-top: 50px;
    font-family: Roboto Mono;
  }

  .video-cell {
    grid-row: 1;
    grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    margin-top: 4px;
    padding-bottom: 16px;
    border-bottom: 1px solid #161619;
    justify-self: right;
    width: 450px;
  }

  .sections {
    grid-column-start: 1;
    grid-column-end: 3;
    -ms-grid-column-span: 2;
    -ms-grid-column: 1;
    grid-row: 3;
    -ms-grid-row: 3;
  }

  .timing-section {
    background: #161619;
    margin-left: -$padding-h;
    margin-right: -$padding-h;
    padding-left: $padding-h;
    padding-right: $padding-h;
  }
}

@include mobile {
  .turnaround {
    display: flex;
    padding: 0px;
    flex-direction: column;

    .empty-apron {
      font-size: 26px;
    }

    .video-cell {
      width: auto;
      order: 1;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .turnaround-info, .empty-apron {
      order: 2;
      padding: 36px 16px;
    }

    .sections {
      order: 3;

      .section_title {
        padding-left: 16px;
        padding-right: 16px;
      }

      .timing-section {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}