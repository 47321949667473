@import "../../styles/constants";
@import "../../styles/breakpoints";

.map-toolbar {
  background: $bk-dark;
  grid-row: 2;
  grid-column: 2;
  -ms-grid-row: 2;
  -ms-grid-column: 2;

  display: flex;
  align-items: center;
  padding: 12px 27px;

  .overview {
    font-weight: 500;
    font-size: 16px;
    color: white;
    text-decoration: none;
    margin-right: 14px;
  }

  .stand-dropdown {
    margin-right: auto;
  }

  .export {
    margin-left: auto;
    margin-right: 0px;
    font-size: 14px;
    color: #A9B1B8;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.06em;
  }
}

@include mobile {
  .map-toolbar {
    display: block;
    padding: 0px;

    .overview, .export {
      display: none;
    }
  }
}