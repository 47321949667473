@import "../../styles/constants";

.tour-button {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 9999999;

  font-size: 20px;
  line-height: 32px;
  width: 34px;
  border-radius: 50%;
  background: #4F5C66;
  border: 1px solid #828F99;
  opacity: 0.8;
  text-align: center;

  &:hover {
    opacity: 1;
    background: #71808B;
    border: 1px solid #828F99;
  }
}

.tour.reactour__helper {
  color: $bk-dark;
  padding: 22px 19px;
  padding-bottom: 0px;
  border-radius: 4px;
  overflow: hidden;

  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 32px;

    a {
      position: relative;
      top:2px;
    }
  }

  .step {
    font-size: 16px;
    margin-bottom: 32px;

    h3 {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.06em;
      color: #4D4F51;
      margin-top: 0px;
      margin-bottom: 16px;
    }
  }

  &>button {
    display: none;
  }

  [data-tour-elem="controls"] {
    display: grid;
    display: -ms-grid;
    grid-template-rows: auto 52px;
    grid-template-columns: 103px 1fr;
    -ms-grid-rows: auto 52px;
    -ms-grid-columns: 103px 1fr;
    margin-left: -19px;
    margin-right: -19px;
  }

  [data-tour-elem="navigation"] {
    flex:1;
    grid-row: 1;
    grid-column: 1 / 3;
    -ms-grid-row: 1;
    -ms-grid-column: 1 / 3;
    margin-bottom: 32px;

  }

  [data-tour-elem="left-arrow"] {
    grid-row: 2;
    grid-column: 1;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    height: 100%;
    background: #E2E2E2;
    font-weight: 500;
    font-size: 14px;
    color: #4D4F51;
    margin-right: 0px;
    text-transform: uppercase;
  }

  [data-tour-elem="right-arrow"] {
    grid-row: 2;
    grid-column: 2;
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    height: 100%;
    background: #008582;
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-left: 0px;
    text-transform: uppercase;
  }

  .reactour__dot--is-active {
    background: #008582;
  }

  [data-tour-elem="dot"]::before, [data-tour-elem="badge"] {
    display: none;
  }

  &.finished {
    [data-tour-elem="controls"] {
      grid-template-columns: 1fr;
      -ms-grid-columns: 1fr;

      [data-tour-elem="right-arrow"] {
        display: none;
      }
    }
  }
}