@import "../../styles/constants";

.finished-turnaround-stream {
  .video-preview {
    position: absolute;
    left: 0px;
    top:0px;
    width: 100%;
    z-index: 10;
  }

  .processing-alert {
    color: white;
    letter-spacing: 0.51px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    white-space: nowrap;
    line-height: 1.5;
    font-size: 16px;
    text-shadow: 1px 1px 2px black;

    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    z-index: 20;
  }


  .progress {
    position: absolute;
    bottom: 100%;
    left: 0px;
    width: 100%;
    background: rgba(232, 232, 232, 0.41);
    z-index: 20;
    cursor: pointer;

    &:hover div {
      height: 10px;
    }

    div {
      height: 4px;
      transition: height 0.2s;
      background: #ED1222;
    }
  }

  .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 16px;

    .spinner {
      position: static;
      width: 16px;
      height: 16px;
    }
  }

  .video-speed-dropdown.dropdown-btn {
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 12px;
    background: none;
    line-height: 20px;
    height: 20px;
    padding: 0px 4px;
    border-radius: 2px;
    color: white;
    margin-right: 5px;

    i {
      margin-left: 4px;
    }
  }
}

.video-speed-dropdown.dropdown-menu {
  background: white;

  a {
    line-height: 20px;
    height: 20px;
    padding: 0px 4px;
    color: black;
    font-size: 12px;
    font-family: Roboto Mono;
    font-weight: 500;

    &:hover {
      background: rgba(0,0,0,.2);
    }

    &.active {
      border-left-color: black;
    }
  }
}
