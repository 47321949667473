@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../Modal/style";

.frame-modal {
  .events-info {
    width: 100%;

    tr:not(:last-child):not(:first-child) {
      border-bottom: 1px solid #E2E2E2;
    }

    th, td {
      text-align: left;
      vertical-align: middle;
    }

    th:first-child, td:first-child {
      padding-left: $modal-padding-h;
      // width: 100%;
    }

    th:last-child, td:last-child {
      padding-right: $modal-padding-h;
    }

    th {
      padding-top: 24px;
      padding-bottom: 16px;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      color: #4D4F51;
      background: #E0E0E0;
    }

    td.label {
      font-size: 16px;
      color: #161619;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    td.confidence {
      width: 80px;

      span {
        line-height: 27px;
        width: 47px;
        display: inline-block;
        text-align: center;
        background: rgba(35, 144, 82, 0.2);
        border-radius: 2px;
        font-family: Roboto Mono;
        font-weight: 500;
        font-size: 14px;
        color: #239052;

        &.low {
          background: rgba(225, 136, 19, 0.15);
          color: #D38741;
        }
      }
    }
    
    td.date-time {
      width: 110px;

      .date {
        font-family: Roboto Mono;
        font-weight: bold;
        font-size: 10px;
        color: #828F99;
        margin-bottom: 3px;
      }

      .time {
        font-family: Roboto Mono;
        font-weight: 500;
        font-size: 14px;
      }
    }

    td.action {
      width: 50px;

      a {
        display: inline-block;
        width: 32px;
        line-height: 32px;
        text-align: center;
        display: inline-block;
        background: #c4c4c47e;
        border-radius: 2px;
        color: #4D4F51;
      }
    }

  }

  .modal-section-title {
   .cameras {
     margin-left: auto;

     a {
       font-weight: 600;
       font-size: 12px;
       position: relative;
       margin-left: 16px;
       cursor: pointer;

       &.active::after {
         content: '';
         position: absolute;
         top: calc(100% + 9px);
         left: 0px;
         height: 2px;
         background: black;
         width: 100%;
       }
     }
   }
  }

  .toolbar {
    background: $modal-section-title-bk;
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0px $modal-padding-h;
    margin-top: -5px;
    position: relative;

    i {
      font-size: 20px;
    }

    .prev10 {
      margin-right: 19px;
    }

    .next {
      margin-right: 19px;
    }

    span {
      margin-left: auto;
      margin-right: auto;
      font-family: Roboto Mono;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .alert {
    padding: 16px $modal-padding-h;
    text-align: center;
    font-weight: 600;
    font-size: 16px;

    i {
      margin-right: 6px;
    }

    &.error {
      color: #BD4E2B;
      background: rgba(203, 10, 10, 0.11);
    }

    &.warning {
      color: #E18813;
      background: rgba(218, 151, 89, 0.2);
    }

    &.success {
      color: #008582;
      background: rgba(31, 28, 57, 0.21);
    }
  }
}
